import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { getUserLocale as getUserLocaleFromBrowser } from 'get-user-locale';
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_DELIVERING,
  ORDER_STATUS_DISPUTED,
  ORDER_STATUS_PAID,
  ORDER_STATUS_PREPARED,
  ORDER_STATUS_PREPARING,
  ORDER_STATUS_REFUNDED,
  ORDER_STATUS_PENDING,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_INHOUSE,
  ORDER_TYPE_SELF_SERVICE,
  ORDER_TYPE_PICKUP,
  ORDER_STATUS_PROCESSING,
  ORDER_TYPE_CATERING,
  CHECKOUT_METHOD_PAY_AND_GO,
  CHECKOUT_METHOD_ORDER_AND_PAY,
  ROUTE_STORE,
  ROUTE_TABLE,
  ROUTE_KIOSK,
  CHECKOUT_METHOD_ORDER_ONLY,
} from '../constants';
import * as MD5 from 'md5.js';
import axios from 'axios';
import {
  APP_FALLBACK_LOCALE,
  APP_SUPPORTED_LOCALES,
  config,
  isCloudflarePages,
} from '../../configs/app.config';
import { notification } from '../notification/notification.service';
import { geocodeByAddress } from 'react-places-autocomplete';
import {
  baseDeleteQuery,
  baseGetQuery,
  basePatchQuery,
} from '../sb-api/sb-api.service';
import { DateTime } from 'luxon';
import { debounce } from 'debounce';

// returns the browser language (ISO 639-1: two-letter codes)
export const getBrowserLanguage = () => {
  const determinedLocale = getUserLocaleFromBrowser({
    fallbackLocale: APP_FALLBACK_LOCALE,
    useFallbackLocale: true,
  })
    .substr(0, 2) // we want the first 2 chars in each case
    .toLowerCase();
  const targetLocale = APP_SUPPORTED_LOCALES.includes(determinedLocale)
    ? determinedLocale
    : APP_FALLBACK_LOCALE;

  return targetLocale;
};

export const formatEuro = (value) => {
  return (
    <CurrencyFormat
      displayType="text"
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      value={value}
      suffix={' €'}
    />
  );
};

export const createRedirect = (redirectUrl) => {
  localStorage.setItem('redirectUrl', redirectUrl || window.location.pathname);
  return window.location.origin + '/redirect';
};

// returns locales in a hierarchical array which represents the order of locales for the current user
export const getLocaleHierarchy = (locale) => {
  const currentLocale = (locale || APP_FALLBACK_LOCALE).toUpperCase();

  return [
    currentLocale,
    ...['EN', 'DE', 'ES', 'IT', 'FR', 'HU'].filter(
      (locale) => locale !== currentLocale,
    ),
  ];
};

// expecting that an object has e.g. nameDE, nameFR, nameIT, ..., this
// function returns the first of them, which is not-nullish, using the
// order from getLocaleHierarchy
// returns null or the value
export const getLocalizedDataProp = (data, propPrefix, locale) =>
  // go through hierarchy and keep the first non-null data prop value
  data && propPrefix
    ? getLocaleHierarchy(locale).reduce(
        (result, propSuffix) => result || data[propPrefix + propSuffix] || '',
        '',
      )
    : null;

export const getLocalizedOrderHint = (data, locale) =>
  // go through hierarchy and keep the first non-null data prop value
  data
    ? getLocaleHierarchy(locale).reduce((result, prop) => {
        console.log(data[prop.toLowerCase()]);
        return result || data[prop.toLowerCase()] || '';
      }, '')
    : null;

// returns false or the name
export const getLocalizedName = (data, locale) =>
  data && getLocalizedDataProp(data, 'name', locale);

export const getLocalizedCategoryName = (data, locale) =>
  data && getLocalizedDataProp(data, 'category', locale);

// returns false or the description
export const getLocalizedDescription = (data, locale) =>
  data && getLocalizedDataProp(data, 'description', locale);

export const getLocalizedTipTitle = (data, locale) =>
  data && getLocalizedDataProp(data, 'tipTitle', locale);

// returns false or the category
export const getLocalizedCategory = (data, locale) =>
  data && getLocalizedDataProp(data, 'category', locale);

export const getLocalizedTitle = (data, locale) =>
  data && getLocalizedDataProp(data, 'title', locale);

// returns false or the image url
export const getImageUrl = (image, variant = 'public') =>
  image && `${config.cfImages.baseUrl}/${image.cfImagesId}/${variant}`;

// returns public or the image variant
export const getImageVariant = (baseVariant) => {
  const ratio =
    Math.min(3, Math.max(1, Math.round(window.devicePixelRatio || '2'))) + 'x'; // => 1x, 2x, 3x,
  return baseVariant ? baseVariant + ratio : 'public';
};

/* export const trimText = (inputText, maxLength = 20, suffix = '...') => {
  if (inputText) {
    const roughlyTrimmedText = inputText.substr(0, maxLength);
    return roughlyTrimmedText === inputText
      ? inputText
      : roughlyTrimmedText.substr(
          0,
          Math.min(
            roughlyTrimmedText.length,
            roughlyTrimmedText.lastIndexOf(' '),
          ),
        ) + (suffix || '');
  } else {
    return '';
  }
}; */

export const trimText = (inputText, maxLength = 30, suffix = '...') => {
  if (inputText) {
    const roughlyTrimmedText = inputText.substr(0, maxLength);
    if (roughlyTrimmedText.length < inputText.length) {
      let lastIndex = roughlyTrimmedText.lastIndexOf(' ');
      if (lastIndex === -1 || lastIndex < maxLength - suffix.length) {
        lastIndex = maxLength - suffix.length;
      }
      return roughlyTrimmedText.substr(0, lastIndex) + suffix;
    } else {
      return inputText;
    }
  } else {
    return '';
  }
};

// returns an array of ids from a given array of objects
export const extractIds = (data) => data?.map(({ id }) => id) || [];

// returns the orderClass for a given orderType
export const getOrderClass = (orderType) => {
  switch (orderType) {
    case ORDER_TYPE_INHOUSE:
    case ORDER_TYPE_SELF_SERVICE:
      return 'inhouse';
    case ORDER_TYPE_PICKUP:
    case ORDER_TYPE_DELIVERY:
    case ORDER_TYPE_CATERING:
      return 'takeaway';
    default:
      console.debug(`No orderClass found for orderType="${orderType}"`);
      return null;
  }
};

// returns true, if orderType is Takeaway-ish
export const isTakeawayOrderClass = (orderType) =>
  getOrderClass(orderType) === 'takeaway';

// returns true, if orderType is Inhouse-ish
export const isInhouseOrderClass = (orderType) =>
  getOrderClass(orderType) === 'inhouse';

// returns an ItemData's / ItemAddition's price, depending on the orderType. Returns NaN on failure.
export const getItemDataOrAdditionPrice = (orderType, object) =>
  (orderType &&
    object &&
    (isInhouseOrderClass(orderType)
      ? object.priceInhouse
      : object.priceTakeaway)) ??
  NaN;

// returns an ItemData's / ItemAddition's tax, depending on the orderType. Returns NaN on failure.
export const getItemDataOrAdditionTaxByOrderType = (orderType, object) =>
  (orderType &&
    object &&
    (isInhouseOrderClass(orderType)
      ? object.taxInhouse
      : object.taxTakeaway)) ??
  NaN;

// returns true, if an ItemData can be ordered
export const isOrderEnabled = (
  store,
  itemData,
  cart,
  checkout,
  table = null,
  kiosk = null,
) => {
  if (!(store && itemData && checkout)) return false; // during render, these properties will become available

  const { posIntegration, paymentProcessor } = store?.merchantProfile || {};
  const itemDataSuffix = `(itemDataId=${itemData.id})(${getLocalizedName(
    itemData,
  )})`;

  // check for MerchantProfile
  if (!store?.merchantProfile) {
    console.debug(
      `Ordering disabled: no MerchantProfile found ${itemDataSuffix}`,
    );
    return false;
  }

  if (
    kiosk &&
    !kiosk.terminal &&
    (checkout.checkoutMethod !== CHECKOUT_METHOD_ORDER_ONLY ||
      store?.merchantProfile?.selfServiceOrderCheckoutMethod !==
        checkout.checkoutMethod)
  ) {
    console.debug(`Ordering disabled: no terminal`);
    return false;
  }

  // check if orderType and checkoutMethod are set
  if (!checkout.orderType || !checkout.checkoutMethod) {
    console.debug(
      `Ordering disabled: orderType="${checkout.orderType}"; checkoutMethod="${checkout.checkoutMethod}" are invalid ${itemDataSuffix}`,
    );
    return false;
  } else if (
    checkout.checkoutMethod === CHECKOUT_METHOD_ORDER_AND_PAY &&
    !paymentProcessor?.canCharge
  ) {
    console.debug(
      `Ordering disabled: checkoutMethod="${checkout.checkoutMethod}" requires paymentProcessor.canCharge=true ${itemDataSuffix}`,
    );
    return false;
  }
  if (table?.area && !table?.area?.isActive) {
    console.debug(`Ordering disabled: table area disabled`);
    return false;
  }
  // check posIntegrationRef
  if (posIntegration && !itemData.posIntegrationRef) {
    console.debug(
      `Ordering disabled: itemData.posIntegrationRef is not set ${itemDataSuffix}`,
    );
    return false;
  } else if (posIntegration && table && !table.posIntegrationRef) {
    console.debug(
      `Ordering disabled: table.posIntegrationRef is not set ${itemDataSuffix}`,
    );
    return false;
  } else if (posIntegration && !posIntegration?.isActive) {
    console.debug(`Ordering disabled: posIntegration is not active`);
    return false;
  }

  if (
    [ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY, ORDER_TYPE_CATERING].includes(
      checkout.orderType,
    ) &&
    !getCurrentAtDate(store.serviceTimes)
  ) {
    return true;
  }

  // check if Store is open
  if (!getCurrentAtDate(store.serviceTimes)) {
    console.debug(`Ordering disabled: Store is closed ${itemDataSuffix}`);
    return false;
  }

  // check for satisfied OrderCondition
  if (
    !hasSatisfiedOrderCondition(
      itemData?.orderConditions,
      checkout.orderType,
      getItemDataTotalSum(cart, itemData, checkout.orderType),
      getItemDataQtySum(cart, itemData),
    )
  ) {
    console.debug(
      `Ordering disabled: No satisfied OrderCondition found ${itemDataSuffix}`,
    );
    return false;
  }

  return true;
};

// returns the total for an array of OrderItems. Returns NaN on failure.
export const getOrderItemsTotal = (orderType, orderItems) =>
  (orderItems || []).reduce(
    (sum, orderItem) => sum + getOrderItemTotal(orderType, orderItem),
    0,
  );

export const calculateCartTotal = getOrderItemsTotal;

// returns the total for a single OrderItem, depending on orderType. Returns NaN on failure.
// set singlePrice=true to force qty=1
export const getOrderItemTotal = (orderType, orderItem, singlePrice = false) =>
  (singlePrice ? 1 : orderItem.qty) *
  ((orderItem.price ??
    getItemDataOrAdditionPrice(orderType, orderItem.itemData)) +
    getOrderItemAdditionsTotal(orderType, orderItem.additions));

// returns the total for an array of OrderItemAdditions, depending on orderType. Returns NaN on failure.
// OrderItem's price property is calculated by the backend. This function will handle both cases, BEFORE sending to backend and AFTER.
export const getOrderItemAdditionsTotal = (orderType, orderItemAdditions) => {
  if ((orderItemAdditions || []).every(({ price }) => price !== undefined)) {
    return (orderItemAdditions || []).reduce(
      (sum, orderItemAddition) =>
        sum + getOrderItemAdditionTotal(orderItemAddition),
      0,
    );
  } else {
    // each AdditionGroupEntry NEEDS to have a group attached
    orderItemAdditions.forEach(({ additionGroupEntry }) => {
      if (!additionGroupEntry?.group?.id) {
        throw new Error(
          `Group is missing for AdditionGroupEntry (id=${additionGroupEntry.id})`,
        );
      }
    });

    // extract unique ItemAdditionGroup IDs
    const uniqueGroupIds = [
      ...new Set(
        orderItemAdditions.map(
          ({ additionGroupEntry }) => additionGroupEntry.group.id,
        ),
      ),
    ];

    // get the total for a group
    const getTotalForItemAdditionGroup = (groupId) => {
      const orderItemAdditionsFromGroup = orderItemAdditions.filter(
        ({ additionGroupEntry }) => additionGroupEntry.group.id === groupId,
      );
      const freeOfChargeSelections =
        orderItemAdditionsFromGroup[0]?.additionGroupEntry.group
          .freeOfChargeSelections || 0; // take group from first addition as it's the same for all additions

      let currentQty = 1;
      let total = 0;

      orderItemAdditionsFromGroup.forEach(({ qty, additionGroupEntry }) => {
        Array.from({ length: qty }).forEach(() => {
          if (currentQty > freeOfChargeSelections) {
            total += getItemDataOrAdditionPrice(
              orderType,
              additionGroupEntry.addition,
            );
          } // else: it's free! No need to add anything to total
          currentQty++;
        });
      });

      return total;
    };

    return uniqueGroupIds.reduce(
      (sum, groupId) => sum + getTotalForItemAdditionGroup(groupId),
      0,
    );
  }
};

// calculates total for a single OrderItemAddition. NEEDS TO HAVE a price, otherwise returns NaN
export const getOrderItemAdditionTotal = (orderItemAddition) =>
  (orderItemAddition.qty - orderItemAddition.freeOfChargeQty) *
  (orderItemAddition.price ?? NaN);

// returns true, if mobile device
export const isMobileDevice = () => window.innerWidth <= 768;

// extracts the Store's slug from me
export const extractMyStoreSlug = (me) => extractMyStore(me, 'slug');

// extracts the Store object from me
export const extractMyStore = (me, prop) => {
  const store = me?.requester?.employeeProfile?.store;
  return store && prop && store[prop] ? store[prop] : store;
};

// returns true, if user is in this own Store
export const isAdminMode = (me, slug) =>
  !!(slug && me?.isStoreOwner && extractMyStoreSlug(me) === slug);

export const getOrderPathType = (orderType) => {
  switch (orderType) {
    case ORDER_TYPE_INHOUSE:
      return 'inhouse';
    case ORDER_TYPE_SELF_SERVICE:
      return 'self-service';
    case ORDER_TYPE_PICKUP:
      return 'pickup';
    case ORDER_TYPE_DELIVERY:
      return 'delivery';
    case ORDER_TYPE_CATERING:
      return 'catering';
    default:
      console.debug(`Cannot determine path type for orderType="${orderType}"`);
      return null;
  }
};

// returns the cookie settings
export const getCookieSettings = () => ({
  allowNecessary: localStorage.getItem('allowNecessaryCookies') || false,
  allowFunctional: localStorage.getItem('allowFunctionalCookies') || false,
  allowMarketing: localStorage.getItem('allowMarketingCookies') || false,
});

// updates the cookie settings
export const setCookieSettings = (
  allowNecessary,
  allowFunctional,
  allowMarketing,
) => {
  localStorage.setItem('allowNecessaryCookies', allowNecessary || false);
  localStorage.setItem('allowFunctionalCookies', allowFunctional || false);
  localStorage.setItem('allowMarketingCookies', allowMarketing || false);
};

// creates a hash string of given data (any type). Returns null on failure.
export const createHash = (data) =>
  (data &&
    new MD5()
      .update(typeof data !== 'string' ? JSON.stringify(data) : data)
      .digest('hex')) ||
  null;

// sets the request defaults (axios, etc.)
export const setRequestDefaults = (
  token,
  isGuest = false,
  setWebSocketToken,
) => {
  // set default timeout to 60 sec
  axios.defaults.timeout = 60 * 1000;

  // set sb-bend url as baseUrl
  // THIS IS NOT WORKING see https://github.com/nuxt-community/axios-module/issues/508
  /*axios.defaults.baseURL = config.apiBaseUrl;*/

  if (token) {
    const targetToken = (!isGuest ? 'Bearer ' : '') + token;
    // auth0 tokens are bearer tokens, otherwise guest token
    axios.defaults.headers.common['Authorization'] = targetToken;
    setWebSocketToken(targetToken);
  } else {
    // unauthenticated user (just visitor)
    delete axios.defaults.headers.common['Authorization'];
  }

  if (isGuest) {
    localStorage.setItem('guestToken', token);
  }
};

// returns an object, where each property is a group defined by the keyProperty
export const groupByKey = (array, keyProp) =>
  array.reduce(
    (res, obj) => ({
      ...res,
      [obj[keyProp]]: (res[obj[keyProp]] || []).concat(obj),
    }),
    {},
  );

// returns a StoreLink or an empty string on failure
export const getStoreLink = (store, isShort = false) =>
  (store &&
    ((isShort && config.shortUrl && `${config.shortUrl}/${store.slug}`) ||
      `${window.location.origin}${ROUTE_STORE}/${store.slug}`)) ||
  '';

// returns a TableLink or an empty string on failure
export const getTableLink = (table, isShort = false) =>
  (table &&
    ((isShort && config.shortUrl && `${config.shortUrl}/t-${table.slug}`) ||
      `${window.location.origin}${ROUTE_TABLE}/${table.slug}`)) ||
  '';
export const getKioskLink = (kiosk) =>
  (kiosk && `${window.location.origin}${ROUTE_KIOSK}/${kiosk.slug}`) || '';

export const buildStoreManifestUrl = (store, locale) => {
  const manifest = {
    name: store?.name,
    short_name: store?.name,
    description: getLocalizedDescription(store?.menu, locale),
    background_color: '#000000',
    theme_color: '#0f4a73',
    icons: [
      {
        src: getImageUrl(store?.logo, 'androidIcon192x192'),
        sizes: '192x192',
      },
      {
        src: getImageUrl(store?.logo, 'androidIcon512x512'),
        sizes: '512x512',
      },
    ],
  };
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });

  return URL.createObjectURL(blob);
};

// capitalizes a string
export const capitalize = (text) =>
  (text && text[0].toUpperCase() + text.slice(1)) || '';

export const extractOrderTaxesByType = (order) => {
  if (!order) return null;

  // collect all tax types
  const taxPerItem = order.items.flatMap(({ qty, itemData, additions }) => {
    const itemDataPrice = getItemDataOrAdditionPrice(order.type, itemData) || 0;
    const itemDataTax =
      getItemDataOrAdditionTaxByOrderType(order.type, itemData) || 0;

    return [
      {
        type: itemDataTax,
        amount: qty * ((itemDataPrice / (itemDataTax + 100)) * itemDataTax),
      },
      ...additions?.map((addition) => {
        const additionsPrice =
          getItemDataOrAdditionPrice(order.type, addition?.itemAddition) || 0;
        const additionsTax =
          getItemDataOrAdditionTaxByOrderType(
            order.type,
            addition?.itemAddition,
          ) || 0;

        return {
          type: additionsTax,
          amount:
            (additionsPrice / (additionsTax + 100)) *
            additionsTax *
            qty *
            addition?.qty,
        };
      }),
    ];
  });

  const taxTypes = [...new Set(taxPerItem.map(({ type }) => type))];

  return taxTypes
    .filter((taxType) => !!taxType) // exclude 0% taxes
    .map((taxType) => ({
      type: taxType,
      total: taxPerItem
        .filter(({ type }) => type === taxType)
        .reduce((total, { amount }) => total + amount, 0),
    }))
    .sort((a, b) => a.type - b.type); // sort asc
};

export const getOrderStatusPillColor = ({ status }) => {
  switch (status) {
    case ORDER_STATUS_PENDING:
    case ORDER_STATUS_PAID:
      return 'light-dark';
    case ORDER_STATUS_PREPARING:
    case ORDER_STATUS_PROCESSING:
      return 'light-warning';
    case ORDER_STATUS_PREPARED:
    case ORDER_STATUS_DELIVERING:
    case ORDER_STATUS_COMPLETED:
    case ORDER_STATUS_REFUNDED:
      return 'light-success';
    case ORDER_STATUS_CANCELLED:
    case ORDER_STATUS_DISPUTED:
      return 'light-danger';
    default:
      return null;
  }
};

export const getOrderTypePillColor = ({ type }) => {
  switch (type) {
    case ORDER_TYPE_INHOUSE:
      return 'light-primary';
    case ORDER_TYPE_SELF_SERVICE:
      return 'light-primary';
    case ORDER_TYPE_DELIVERY:
      return 'light-warning';
    case ORDER_TYPE_PICKUP:
      return 'light-danger';
    default:
      return null;
  }
};

// returns one Date object per month (local TZ)
export const getMonthsInRange = (startDate, endDate) => {
  const currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    1,
  );
  const dates = [];
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dates;
};

export const getDaysInRange = (startDate, endDate) => {
  // Normalize the endDate to the start of the day (00:00) to include the full day
  const normalizedEndDate = new Date(endDate);
  normalizedEndDate.setHours(23, 59, 59, 999); // Ensure endDate includes the full day

  const currentDate = new Date(startDate);
  const dates = [];

  while (currentDate <= normalizedEndDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  console.log('Dates:', dates);
  console.log('Start Date:', startDate);
  console.log('Normalized End Date:', normalizedEndDate);
  console.log('Current Date (last):', currentDate);
  return dates;
};

// returns first Date of month (local TZ)
export const getFirstDateOfMonth = (date, monthDelta = 0, x = 1) =>
  new Date(date.getFullYear(), date.getMonth() + monthDelta, x);

// returns last Date of month (local TZ)
export const getLastDateOfMonth = (date, monthDelta = 0) =>
  new Date(
    date.getFullYear(),
    date.getMonth() + monthDelta + 1,
    0,
    23,
    59,
    59,
    999,
  );

export const getPaymentMethodDetails = (paymentMethod) => {
  if (paymentMethod?.type) {
    const baseIconPath = '/assets/icons/payment-logos/';
    switch (paymentMethod?.type) {
      case 'card':
        return {
          name: 'Credit Card',
          iconSrc: baseIconPath + paymentMethod.card.brand + '.svg',
          suffix: '**** ' + paymentMethod.card.last4,
          expiresAt:
            paymentMethod.card.exp_month + '/' + paymentMethod.card.exp_year,
        };
      case 'sepa_debit':
        return {
          name: 'Sepa Debit',
          iconSrc: baseIconPath + 'elv.svg',
          suffix: '**** ' + paymentMethod.sepa_debit.last4,
          expiresAt: null,
        };
      case 'paypal':
        return {
          name: 'PayPal',
          iconSrc: baseIconPath + 'paypal.svg',
          suffix: paymentMethod.paypal.payer_email,
          expiresAt: null,
        };
      case 'klarna':
        return {
          name: 'Klarna',
          iconSrc: baseIconPath + 'klarna.svg',
          suffix: paymentMethod.klarna.payer_email,
          expiresAt: null,
        };
      default:
        break;
    }
  } else {
    return {};
  }
};

export const getNextOrderStatus = (currentStatus, orderType) => {
  switch (currentStatus) {
    case ORDER_STATUS_PENDING:
      return ORDER_STATUS_PAID;
    case ORDER_STATUS_PROCESSING:
      return ORDER_STATUS_PREPARING;
    case ORDER_STATUS_PREPARING:
      return ORDER_STATUS_PREPARED;
    case ORDER_STATUS_PREPARED:
      return orderType === ORDER_TYPE_DELIVERY
        ? ORDER_STATUS_DELIVERING
        : ORDER_STATUS_COMPLETED;
    case ORDER_STATUS_DELIVERING:
      return ORDER_STATUS_COMPLETED;
    default:
      return null;
  }
};

// returns how long the given timestamp is ago (in seconds)
export const calculateAgoSeconds = (timestamp) =>
  (new Date(timestamp) - new Date()) / 1000;

export const calculateUpdateIntervalSeconds = (timestamp) =>
  calculateAgoSeconds(timestamp) < 60
    ? 1
    : calculateAgoSeconds(timestamp) < 60 * 60
    ? 60
    : 0;

export const calculateLighterAndDarker = (hexColor, intensity = 0.1) => {
  //intensity should be decimal number exmple : 0.1
  const color = hexColor.replace('#', '');
  const num = parseInt(color, 16);

  // Calculate lighter color
  const amount = intensity; // adjust this value to make the color lighter or darker
  const r = Math.min(255, parseInt((num >> 16) * (1 + amount), 10));
  const g = Math.min(255, parseInt(((num >> 8) & 0x00ff) * (1 + amount), 10));
  const b = Math.min(255, parseInt((num & 0x0000ff) * (1 + amount), 10));
  const lighterHex =
    '#' + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  // Calculate darker color
  const amount2 = intensity; // adjust this value to make the color lighter or darker
  const r2 = Math.max(0, parseInt((num >> 16) * (1 - amount2), 10));
  const g2 = Math.max(0, parseInt(((num >> 8) & 0x00ff) * (1 - amount2), 10));
  const b2 = Math.max(0, parseInt((num & 0x0000ff) * (1 - amount2), 10));
  const darkerHex =
    '#' + ((r2 << 16) | (g2 << 8) | b2).toString(16).padStart(6, '0');

  return { lighter: lighterHex, darker: darkerHex };
};

export const calculateTextColor = (hexColor) => {
  const color = hexColor.replace('#', '');
  const num = parseInt(color, 16);

  // Calculate perceived brightness of the color
  const r = (num >> 16) & 0xff;
  const g = (num >> 8) & 0xff;
  const b = num & 0xff;
  const perceivedBrightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return white or black based on perceived brightness
  return perceivedBrightness > 0.5 ? '#000000' : '#FFFFFF';
};

export const parseLocaleNumber = (stringNumber, locale) => {
  // determines the thousands separator of the current locale (by trying out)
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');

  // determines the decimal separator of the current locale (by trying out)
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '') // remove all thousand separators
      .replace(new RegExp('\\' + decimalSeparator), '.'), // and replace decimal separators with a dot
  );
};

export const createLocaleNumberParser = (locale) => {
  const formatter = new Intl.NumberFormat(locale);
  const thousandSeparator = formatter.format(11111).replace(/\p{Number}/gu, '');
  const decimalSeparator = formatter.format(1.1).replace(/\p{Number}/gu, '');

  // Precompile regex for performance
  const thousandSeparatorRegex = new RegExp('\\' + thousandSeparator, 'g');
  const decimalSeparatorRegex = new RegExp('\\' + decimalSeparator);

  return (stringNumber) => {
    // Check if stringNumber is a valid string
    if (typeof stringNumber !== 'string') {
      return NaN; // or handle this case based on your requirements
    }

    // Replace thousand separators and convert decimal separator to dot
    const cleanedString = stringNumber
      .replace(thousandSeparatorRegex, '')
      .replace(decimalSeparatorRegex, '.');

    // Parse the cleaned string into a number
    const parsedNumber = parseFloat(cleanedString);

    // Check if parsedNumber is a valid number
    if (!isNaN(parsedNumber)) {
      return parsedNumber;
    } else {
      return NaN; // or handle this case based on your requirements
    }
  };
};

export const filterDuplicates = (array) => {
  const seenIds = new Set();
  return array.filter((item) => {
    if (seenIds.has(item.id)) {
      return false; // Duplicate ID, exclude from the filtered array
    }
    seenIds.add(item.id);
    return true; // Unique ID, include in the filtered array
  });
};

export const hexToRGBA = (hex, opacity) => {
  hex = hex.replace('#', '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const setColorVars = (store) => {
  // in case we are in sign up component
  const root = document.documentElement;
  const globalStyle = getComputedStyle(document.documentElement);
  const textColor = calculateTextColor(
    globalStyle.getPropertyValue('--primary'),
  );
  root.style.setProperty('--text-color', textColor);

  if (store) {
    store?.primaryColor &&
      root.style.setProperty('--primary', store?.primaryColor);
    store?.secondaryColor &&
      root.style.setProperty('--secondary', store?.secondaryColor);

    if (store?.primaryColor) {
      const primaryColorCalculated = calculateLighterAndDarker(
        store.primaryColor,
        0.1,
      );
      //const backgroundColorCalculated = calculateLighterAndDarker('#f8f8f8', 0.3);

      const textColor = calculateTextColor(store.primaryColor);
      const textColorCard = calculateTextColor('#ffffff');

      root.style.setProperty('--body-bg', '#f6f6f6');
      root.style.setProperty(
        '--primary-lighter',
        primaryColorCalculated?.lighter,
      );
      root.style.setProperty(
        '--primary-darker',
        primaryColorCalculated?.darker,
      );
      root.style.setProperty('--text-color', textColor);
      root.style.setProperty('--text-color-card', textColorCard);
      root.style.setProperty(
        '--primary-rgb',
        hexToRGBA(globalStyle.getPropertyValue('--primary'), 0.2),
      );
    } else {
      const primaryColorCalculated = calculateLighterAndDarker(
        globalStyle.getPropertyValue('--primary'),
        0.1,
      );
      root.style.setProperty(
        '--primary-lighter',
        primaryColorCalculated?.lighter,
      );
      root.style.setProperty(
        '--primary-darker',
        primaryColorCalculated?.darker,
      );
      const textColor = calculateTextColor(primaryColorCalculated?.darker);
      const textColorCard = calculateTextColor('#ffffff');
      root.style.setProperty('--text-color', textColor);
      root.style.setProperty('--text-color-card', textColorCard);
    }
  }
};

// creates a new kiosk session
export const initKioskSession = (kiosk, store) => {
  if (!kiosk || !store) {
    throw new Error('Cannot init Kiosk Session: kiosk or store missing');
  }
  const kioskSession = {
    kiosk: {
      id: kiosk.id,
      slug: kiosk.slug,
      name: kiosk.name,
    },
    storeId: store.id,
  };

  localStorage.setItem('kioskSession', JSON.stringify(kioskSession));
  console.info(`Started session on Kiosk (id=${kiosk.id})`);
};

// either tableSession kioskSession
export const getSession = (key) => {
  const sessionJson = localStorage.getItem(key);

  if (sessionJson) {
    let session;
    try {
      session = JSON.parse(sessionJson);
    } catch (e) {
      localStorage.removeItem(key);
      console.error(`Deleted ${key} as it cannot be parsed: "${sessionJson}"`);
      return null;
    }

    return session;
  }

  return null;
};

// creates a new table session
export const initTableSession = (table, store) => {
  if (!table || !store) {
    throw new Error('Cannot init Table Session: table or store missing');
  }
  const tableSession = {
    table: {
      id: table.id,
      slug: table.slug,
      name: table.name,
    },
    storeId: store.id,
    lastActionAt: new Date(),
  };

  localStorage.setItem('tableSession', JSON.stringify(tableSession));
  console.info(`Started session on Table (id=${table.id})`);
};

export const updateTableSession = debounce(
  () => {
    const tableSession = getSession('tableSession');

    // update lastActionAt
    localStorage.setItem(
      'tableSession',
      JSON.stringify({
        ...tableSession,
        lastActionAt: new Date(),
      }),
    );

    console.debug('Updated TableSession lastActionAt to now');
  },
  3000,
  true,
);

export const calculateDistance = (point1, point2) => {
  const earthRadius = 6371; // Radius of the Earth in kilometers

  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const dLat = toRadians(point2?.lat - point1?.lat);
  const dLon = toRadians(point2?.lng - point1?.lng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(point1?.lat)) *
      Math.cos(toRadians(point2?.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadius * c;
};

// used to retry resolving a Promise
export const withRetry = (fn, retries = 25, interval = 2000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((err) => {
        setTimeout(() => {
          const statusCode = err?.response?.status;

          // we don't need to try it again if no retries left or statusCode=408,429 or >=500
          if (
            retries === 0 ||
            (statusCode &&
              !(statusCode === 408 || statusCode === 429 || statusCode >= 500))
          ) {
            reject(err);
          } else {
            console.error(
              `Retrying to send request (${retries} retries left) ...`,
            );
            withRetry(fn, retries - 1, interval).then(resolve, reject);
          }
        }, interval);
      });
  });
};

export const extractAddressInfo = (response) => {
  const { addressComponents } = response;
  const extractedAddress = {};

  for (const component of addressComponents) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'route':
        extractedAddress.street = component?.longText;
        break;

      case 'locality':
        extractedAddress.city = component?.longText;
        break;

      case 'country':
        extractedAddress.country = component?.shortText;
        break;
      case 'street_number':
        extractedAddress.streetNumber = component?.shortText;
        break;
      case 'postal_code':
        extractedAddress.zip = component?.shortText;
        break;
      case 'administrative_area_level_1':
        extractedAddress.state = component?.shortText;
        break;

      default:
        break;
    }
  }
  return extractedAddress;
};

export const fetchLocation = async (line1) => {
  const results = await geocodeByAddress(line1);
  const location = await axios.get(
    `https://places.googleapis.com/v1/places/${results[0]?.place_id}?fields=addressComponents,location&key=${config.google.apiKey}`,
    {
      // remove Authorization because this is not the SB Backend
      transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
        return data;
      },
      timeout: 120 * 1000, // set timeout here to 120 sec
    },
  );
  return location;
};

export const replaceColorInLottie = (
  lottieData,
  newPrimaryHex,
  newSecondaryHex = null,
  newAlt1Hex = null,
  newAlt2Hex = null,
) => {
  // Helper function to convert hex color to RGBA string array
  const hexToRgbaStringArray = (hex) => {
    if (hex.length === 4) hex += hex.slice(1, 3);
    const r = (parseInt(hex.slice(1, 3), 16) / 255).toFixed(12);
    const g = (parseInt(hex.slice(3, 5), 16) / 255).toFixed(12);
    const b = (parseInt(hex.slice(5, 7), 16) / 255).toFixed(12);
    return `[${r},${g},${b}`; // don't close array, there might be a 4th alpha parameter
  };

  let newLottieDataString = JSON.stringify(lottieData);
  const newHexStrings = [
    newPrimaryHex,
    newSecondaryHex,
    newAlt1Hex,
    newAlt2Hex,
  ];
  const oldColorStrings = [
    '[0.88888888,0.88888888,0.88888888', // don't close array strings, there might be a 4th alpha parameter
    '[0.86666667,0.86666667,0.86666667',
    '[0.93333333,0.93333333,0.93333333',
    '[0.9372549,0.9372549,0.9372549',
  ];

  newHexStrings.forEach((newHex, index) => {
    if (newHex) {
      // Replace occurrences of the original color with the new color
      newLottieDataString = newLottieDataString.replaceAll(
        oldColorStrings[index],
        hexToRgbaStringArray(newHex),
      );
    }
  });

  // Parse the modified string back to a JSON object
  return JSON.parse(newLottieDataString);
};

export const areDatesWithinMinutes = (startTime, endTime, minutes) => {
  const startTimestamp = new Date(startTime).getTime();
  const endTimestamp = new Date(endTime).getTime();
  const minutesInMillis = minutes * 60 * 1000;

  return startTimestamp + minutesInMillis >= endTimestamp;
};

export const roundDate = (date) => {
  const clonedDate = new Date(date);
  const milliseconds = clonedDate.getMilliseconds();
  clonedDate.setMilliseconds(0);

  if (milliseconds >= 500) {
    clonedDate.setSeconds(clonedDate.getSeconds() + 1);
  }

  return clonedDate;
};

// prepares OrderItems from Cart to be sent in a Request to Backend
export const convertCartToOrderItems = (cart) =>
  cart.map((orderItem) => {
    return {
      ...orderItem,
      itemData: undefined,
      itemDataId: orderItem.itemData.id,
      additions: orderItem.additions?.map((orderItemAddition) => ({
        ...orderItemAddition,
        additionGroupEntryId: orderItemAddition.additionGroupEntry.id,
        itemAddition: undefined,
      })),
    };
  });

export const getOrderBody = (
  checkout,
  cart,
  table,
  merchantProfileId = undefined,
  kiosk = undefined,
) => {
  const isPayAndGo = checkout.checkoutMethod === CHECKOUT_METHOD_PAY_AND_GO;

  return {
    merchantProfileId,
    checkoutMethod: checkout.checkoutMethod,
    billingAddressId: checkout?.billingAddressId,
    cartComment: checkout.cartComment,
    tip: checkout.tip,
    discountCode: checkout.discountCode,
    ...(!isPayAndGo && { items: convertCartToOrderItems(cart) }),
    ...(table &&
      (checkout.orderType === ORDER_TYPE_INHOUSE ||
        checkout.orderType === ORDER_TYPE_SELF_SERVICE) && {
        tableId: table.id,
        pagerNumber: checkout.pagerNumber,
      }),
    ...(checkout.orderType !== ORDER_TYPE_DELIVERY && {
      customerName: checkout?.customerName,
      customerTel: checkout?.customerTel,
      customerEmail: checkout?.customerEmail,
    }),
    ...(kiosk &&
      (checkout.orderType === ORDER_TYPE_SELF_SERVICE ||
        checkout.orderType === ORDER_TYPE_PICKUP) && {
        kioskId: kiosk.id,
        pagerNumber: checkout.pagerNumber,
        pickupRequestedAt: DateTime.now().plus({ minutes: 5 }), // if PickupOrder at a Kiosk, set this to 5min
      }),
    ...(checkout.orderType === ORDER_TYPE_PICKUP &&
      !kiosk && {
        pickupRequestedAt: checkout?.pickupRequestedAt,
      }),
    ...((checkout.orderType === ORDER_TYPE_DELIVERY ||
      checkout.orderType === ORDER_TYPE_CATERING) && {
      deliveryAddressId: checkout.deliveryAddressId,
      deliveryRequestedAt: checkout?.deliveryRequestedAt,
    }),
    ...(checkout.orderType === ORDER_TYPE_CATERING && {
      guestQty: checkout?.guestQty,
    }),
    paymentMethodFutureUsage: checkout.paymentMethodFutureUsage,
  };
};

export const hasScroll = (containerSelector) => {
  const containerElement = document.querySelector(containerSelector);

  if (!containerElement) return false;

  return containerElement.scrollHeight > containerElement.clientHeight;
};

export const bulkActionTool = async () => {
  const scopeOptions = [
    'items/food',
    'item-data/food',
    'item-additions/food',
    'item-categories',
    'tables',
    'orders',
    'discount-codes',
    'table-reservations',
  ];

  // User Input and Validation
  let scope;
  while (!scope) {
    scope = window.prompt(
      `[SCOPE] Please select a scope:\n${scopeOptions.join('\n')}`,
    );
    if (scope === null) return; // User canceled prompt, abort execution

    if (!scopeOptions.includes(scope)) {
      window.alert('Invalid scope. Please choose from available options.');
      scope = undefined;
    }
  }

  let query;
  query = window.prompt(
    `[QUERY] (optional) Please insert a query. If empty, all Entities from Scope "${scope}" will be selected. You need to set a limit, otherwise only 25 entries will be processed. Examples:\n\n` +
      [
        [
          'All Items in the Root Category',
          '?join=categories&filter=categories.id||$isnull&limit=9999',
        ],
        [
          'All ItemData whose nameEN contains "Dessert"',
          '?filter=nameEN||$cont||Dessert&limit=9999',
        ],
        [
          'All ItemData whose taxInhouse is 7',
          '?filter=taxInhouse||$eq||7&limit=9999',
        ],
        [
          'ItemData created before 2024-01-01 AND taxInhouse is 7',
          '?s={"taxInhouse": 7, "createdAt": {"$lt": "2024-01-01"}}',
        ],
      ]
        .map((set) => set.join('\n'))
        .join('\n\n'),
  );
  if (query === null) return; // User canceled prompt, abort execution

  // Fetch Target Entities
  const targetEntitiesPath = scope + query || '';
  const targetEntities = await baseGetQuery(targetEntitiesPath)
    .then(({ data }) => data)
    .catch((err) => {
      window.alert(`Error: ${err}`);
      return [];
    });
  const targetEntityIds = targetEntities.map(({ id }) => id);

  // User Confirmation and Action Choice
  if (targetEntityIds.length === 0) {
    window.alert('No entities found for this query. Please retry.');
    return;
  }

  console.log(`Found ${targetEntityIds.length} target entities.`);

  const actionOptions = ['update', 'delete'];
  let action;
  while (!actionOptions.includes(action)) {
    action = window.prompt(
      `[ACTION] Please select an action:\n${actionOptions.join('\n')}`,
    );

    if (action === null) return; // User canceled prompt, abort execution

    if (!actionOptions.includes(action)) {
      window.alert('Invalid action. Please choose from available options.');
      action = undefined;
    }
  }

  // Update Entities
  let payload;
  if (action === 'update') {
    while (!payload) {
      payload = window.prompt('[PAYLOAD] Please insert your payload (JSON):');

      if (payload === null) return; // User canceled prompt, abort execution

      try {
        payload = JSON.parse(payload);
      } catch (error) {
        window.alert('Invalid JSON format. Please try again.');
        payload = undefined;
      }
    }
  }

  // Confirmation before Execution
  const confirmation = window.confirm(
    `Are you sure you want to ${action} ${targetEntityIds.length} entities? This action cannot be undone!`,
  );
  if (!confirmation) return; // User canceled prompt, abort execution

  // Loop for Bulk Actions
  let successfulOperations = 0;
  for (const id of targetEntityIds) {
    const actionPath = scope + `/${id}`;
    try {
      switch (action) {
        case 'update':
          await basePatchQuery(actionPath, payload);
          successfulOperations++;
          break;
        case 'delete':
          await baseDeleteQuery(actionPath);
          successfulOperations++;
          break;
      }
    } catch (error) {
      console.error(`Error performing ${action} on entity ${id}:`, error);
    }
  }

  // Final Success Message
  window.alert(
    `${successfulOperations} out of ${targetEntityIds.length} entities ${action}d successfully. Reload the Page to see the results!`,
  );
};

global.sbcli = { bulkActionTool };

const transformToWeek = (serviceTime, dateInTargetWeek) => {
  const parseDate = (date) =>
    typeof date === 'string'
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date);

  const startOfServiceTimeWeek = parseDate(serviceTime.startAt).startOf('week');
  const startOfTargetWeek = parseDate(dateInTargetWeek || new Date()).startOf(
    'week',
  );
  const difference = startOfTargetWeek.diff(startOfServiceTimeWeek);

  return {
    ...serviceTime,
    startAt: parseDate(serviceTime.startAt)
      .plus(difference)
      .toJSDate(),
    endAt: parseDate(serviceTime.endAt)
      .plus(difference)
      .toJSDate(),
  };
};

const transformMultipleToWeek = (serviceTimes, dateInTargetWeek) => {
  return [...(serviceTimes || [])]
    ?.sort((a, b) => new Date(a) - new Date(b))
    .map((serviceTime) => transformToWeek(serviceTime, dateInTargetWeek));
};

export const getCurrentAtDate = (serviceTimes, targetDate = new Date()) => {
  return transformMultipleToWeek(serviceTimes, targetDate).find(
    ({ startAt, endAt }) =>
      DateTime.fromJSDate(startAt) <= DateTime.fromJSDate(targetDate) &&
      DateTime.fromJSDate(endAt) > DateTime.fromJSDate(targetDate),
  );
};

export const getNextStart = (serviceTimes, targetDate = new Date()) => {
  // it is possible, that the next startAt is in the next week. So concatenate targetDate's week and targetDate+1's week
  return [
    ...transformMultipleToWeek(serviceTimes, targetDate),
    ...transformMultipleToWeek(
      serviceTimes,
      DateTime.fromJSDate(targetDate)
        .plus({ week: 1 })
        .toJSDate(),
    ),
  ].find(
    ({ startAt }) =>
      DateTime.fromJSDate(startAt) > DateTime.fromJSDate(targetDate),
  );
};

/*const getDay = (
  serviceTimes,
  timeZone = 'Europe/Berlin',
  dateInTargetDay = new Date(),
) => {
  const startOfDay = DateTime.fromJSDate(dateInTargetDay)
    .setZone(timeZone)
    .startOf('day');
  const endOfDay = startOfDay.endOf('day');
  const targetServiceTimes = serviceTimes?.filter(({ startAt, endAt }) => {
    const start = DateTime.fromJSDate(startAt);
    const end = DateTime.fromJSDate(endAt);
    return (
      (startOfDay <= start && endOfDay >= start) ||
      (startOfDay <= end && endOfDay >= end)
    );
  });

  if (!targetServiceTimes) throw new Error('Cannot find suitable ServiceTimes');

  return targetServiceTimes;
};*/

export const isOrderConditionSatisfied = (
  orderCondition,
  orderClass = undefined,
  total = undefined,
  qty = undefined,
  targetDate = new Date(),
) => {
  const currentServiceTime = getCurrentAtDate(
    orderCondition.serviceTimes,
    targetDate,
  );
  const isServiceTimeSatisfied =
    !orderCondition.serviceTimes?.length || !!currentServiceTime; // no ServiceTimes = always active
  const isOrderClassSatisfied =
    !orderClass || orderCondition.classes.includes(orderClass);
  /*const isMinTotalSatisfied =
    !isNumber(total) || orderCondition.minTotal <= total;
  const isMaxTotalSatisfied =
    !isNumber(total) ||
    orderCondition.maxTotal === null ||
    orderCondition.maxTotal >= total;*/
  const isMinQtySatisfied = !isNumber(qty) || orderCondition.minQty <= qty;
  const isMaxQtySatisfied =
    !isNumber(qty) ||
    orderCondition.maxQty === null ||
    orderCondition.maxQty >= qty;

  return (
    orderCondition.isActive &&
    isServiceTimeSatisfied &&
    isOrderClassSatisfied &&
    /*isMinTotalSatisfied &&
    isMaxTotalSatisfied &&*/
    isMinQtySatisfied &&
    isMaxQtySatisfied
  );
};

export const hasSatisfiedOrderCondition = (
  orderConditions,
  orderType,
  total,
  qty,
  targetDate = new Date(),
) => {
  const isDisabled = orderConditions?.filter(
    (orderCondition) => !orderCondition?.isActive,
  );

  return (
    (isDisabled?.length > 0 &&
      isDisabled?.length === orderConditions?.length) ||
    orderConditions?.length === 0 ||
    !!getSatisfiedOrderCondition(
      orderConditions,
      getOrderClass(orderType),
      total,
      qty,
      targetDate,
    )
  );
};

export const getSatisfiedOrderCondition = (
  orderConditions,
  orderClass = null,
  total = null,
  qty = null,
  targetDate = new Date(),
) =>
  orderConditions
    ?.sort((a, b) => b.priority - a.priority) // sort DESC by priority (high priority first)
    .find((orderCondition) =>
      isOrderConditionSatisfied(
        orderCondition,
        orderClass,
        total,
        qty,
        targetDate,
      ),
    );

export const mergeOrderConditions = (
  orderConditions,
  targetDate = new Date(), // now as default
) =>
  orderConditions?.length
    ? orderConditions.reduce((result, current) => ({
        isActive: result.isActive || current.isActive,
        classes: result.classes.filter((type) =>
          current.classes.includes(type),
        ), // include all classes that are already known (=intersection)
        priority: Math.max(result.priority, current.priority),
        minQty: Math.max(result.minQty, current.minQty),
        maxQty: Math.min(result.maxQty, current.maxQty),
        minTotal: Math.max(result.minTotal, current.minTotal),
        maxTotal: Math.min(result.maxTotal, current.maxTotal),
        serviceTimes: mergeServiceTimes(
          [
            getCurrentAtDate(result.serviceTimes, targetDate),
            getCurrentAtDate(current.serviceTimes, targetDate),
          ].filter(Boolean),
        ),
      }))
    : null;

export const mergeServiceTimes = (serviceTimes) => {
  const isTimeZoneConsistent = serviceTimes.every(
    ({ timeZone }) => timeZone === serviceTimes[0].timeZone,
  );

  if (!isTimeZoneConsistent)
    throw new Error('Cannot merge ServiceTimes with different Time Zones');

  return serviceTimes?.length
    ? serviceTimes.reduce((result, current) => {
        const startAt =
          result.startAt > current.startAt ? result.startAt : current.startAt;
        const endAt =
          result.endAt < current.endAt ? result.endAt : current.endAt;

        return {
          ...current,
          startAt,
          endAt,
          id: undefined,
        };
      })
    : null;
};

// generates a unique hash for itemData + additionGroupEntry combinations
// to be able to identify, if this item is already in the cart
export const getOrderItemHash = ({ itemData, additions }) => {
  const ids =
    itemData?.id +
    [...(additions || [])]
      .sort((a, b) =>
        a.additionGroupEntry?.id.localeCompare(b.additionGroupEntry?.id),
      )
      .reduce(
        (hash, { qty, additionGroupEntry }) =>
          hash + '+' + qty + additionGroupEntry.id,
        '',
      );

  return createHash(ids);
};

// returns the sum of all quantities in an array of OrderItems (= cart) for an ItemData
export const getItemDataQtySum = (orderItems, itemData) =>
  (itemData &&
    orderItems?.reduce(
      (sum, { id, qty }) => sum + (id === itemData.id ? qty : 0),
      0,
    )) ||
  NaN;

// returns the sum of all totals in an array of OrderItems (= cart) for an ItemData
export const getItemDataTotalSum = (orderItems, itemData, orderType) =>
  getItemDataQtySum(orderItems, itemData) *
  getItemDataOrAdditionPrice(orderType, itemData);

// returns a subset of the given OrderItems, while each OrderItem contains only the given ItemAddition
export const getOrderItemsContainingItemAddition = (orderItems, itemAddition) =>
  itemAddition &&
  (orderItems || [])
    .filter(({ additions }) =>
      additions.some(
        ({ additionGroupEntry }) =>
          additionGroupEntry.addition.id === itemAddition.id,
      ),
    )
    .map((orderItem) => ({
      ...orderItem,
      additions: orderItem.additions.filter(
        ({ additionGroupEntry }) =>
          additionGroupEntry.addition.id === itemAddition.id,
      ),
    }));

// returns the sum of all quantities in an array of OrderItems (= cart) for an ItemAddition
export const getItemAdditionQtySum = (cart, itemAddition) => {
  return getOrderItemsContainingItemAddition(cart, itemAddition).reduce(
    (sum, { qty, additions }) =>
      sum + qty * additions.reduce((sum, { qty }) => sum + qty, 0),
    0,
  );
};

// returns the sum of all totals in an array of OrderItems (= cart) for an ItemAddition
export const getItemAdditionsTotalSum = (cart, itemAddition, orderType) =>
  getItemAdditionQtySum(cart, itemAddition) *
  getItemDataOrAdditionPrice(orderType, itemAddition);

// returns an array of available ItemAdditionGroups, including their entries
export const getAvailableItemAdditionGroups = (
  additionGroups,
  orderType,
  posIntegration = null,
) =>
  [...(additionGroups || [])]
    // check OrderConditions on ItemAdditionGroup level
    .filter(
      ({ orderConditions }) =>
        !orderConditions?.length ||
        getSatisfiedOrderCondition(orderConditions, getOrderClass(orderType)),
    )
    .map((additionGroup) => ({
      ...additionGroup,
      entries: additionGroup.entries
        // check posIntegration
        .filter(
          ({ addition }) => !posIntegration || !!addition?.posIntegrationRef,
        )
        // check OrderConditions on ItemAdditionGroupEntry level
        .filter(
          ({ orderConditions }) =>
            !orderConditions?.length ||
            getSatisfiedOrderCondition(
              orderConditions,
              getOrderClass(orderType),
            ),
        )
        // check OrderConditions on ItemAddition level
        .filter(
          ({ addition }) =>
            !addition?.orderConditions?.length ||
            getSatisfiedOrderCondition(
              addition?.orderConditions,
              getOrderClass(orderType),
            ),
        )
        // sort by manualRanking (ItemAdditionGroupEntry level)
        .sort((a, b) => a.manualRanking - b.manualRanking),
    }))
    // check if there are any entries left
    .filter(({ entries }) => entries?.length > 0)
    // sort by manualRanking (ItemAdditionGroup level)
    .sort((a, b) => a.manualRanking - b.manualRanking);

// returns the price of an ItemData + the prices of all pre-selected ItemAdditionEntries
export const getItemDataPriceWithPreSelections = (
  orderType,
  itemData,
  posIntegration = null,
) => {
  // these are the pre-selected ItemAdditionGroupEntries, packed in OrderItemAdditions
  const additions = getAvailableItemAdditionGroups(
    itemData?.additionGroups,
    orderType,
    posIntegration,
  ).flatMap((group) =>
    group.entries
      .filter(({ isPreSelected }) => isPreSelected)
      .map((entry) => ({
        qty: 1, // currently it's only possible to pre-select qty=1
        additionGroupEntry: {
          ...entry,
          group: { ...group, entries: undefined }, // exclude entries to avoid circular structure
        },
      })),
  );

  return getOrderItemTotal(orderType, { itemData, additions }, true);
};

// returns true if given value is a number and not NaN
export const isNumber = (value) =>
  typeof value === 'number' && !Number.isNaN(value);

// returns true if given orderType is valid
export const isOrderTypeValid = (orderType) =>
  [
    ORDER_TYPE_INHOUSE,
    ORDER_TYPE_SELF_SERVICE,
    ORDER_TYPE_PICKUP,
    ORDER_TYPE_DELIVERY,
    ORDER_TYPE_CATERING,
  ].includes(orderType);

// returns the checkoutMethod for a given orderType
export const getCheckoutMethod = (orderType, merchantProfile, area) => {
  if (!merchantProfile) {
    throw new Error(
      'Cannot determine CheckoutMethod: merchantProfile is missing',
    );
  }

  if (area?.checkoutMethod) {
    return area.checkoutMethod;
  }

  switch (orderType) {
    case ORDER_TYPE_INHOUSE:
      return merchantProfile.inhouseOrderCheckoutMethod;
    case ORDER_TYPE_PICKUP:
      return merchantProfile.pickupOrderCheckoutMethod;
    case ORDER_TYPE_DELIVERY:
      return merchantProfile.deliveryOrderCheckoutMethod;
    case ORDER_TYPE_CATERING:
      return merchantProfile.cateringOrderCheckoutMethod;
    case ORDER_TYPE_SELF_SERVICE:
      return merchantProfile.selfServiceOrderCheckoutMethod;
    default:
      console.error(
        `No valid CheckoutMethod found for orderType="${orderType}"`,
      );
      return null;
  }
};

export const getOrderErrorMessage = (error) => {
  let errorMessageId;

  switch (error?.response?.data?.error) {
    case 'NO_TABLE_CART_SELECTIONS_FOUND':
      errorMessageId = 'noTableCartSelectionsFoundError';
      break;
    case 'DISCOUNT_CODE_NOT_FOUND':
    case 'DISCOUNT_CODE_STORE_INVALID':
      errorMessageId = 'invalidDiscountCodeError';
      break;
    case 'DISCOUNT_CODE_EXPIRED':
      errorMessageId = 'discountCodeExpiredError';
      break;
    case 'STORE_CLOSED_AT_REQUESTED_TIME':
      errorMessageId = 'storeClosedAtRequestedTimeError';
      break;
    case 'STORE_CURRENTLY_CLOSED':
      errorMessageId = 'storeIsClosedNotification';
      break;
    default:
      errorMessageId = 'defaultResponseOrderError';
      break;
  }

  return errorMessageId;
};

export const getOrderClassesFromItemData = (itemData) => {
  if (itemData?.orderConditions.length > 0) {
    const availableOrderClasses = itemData?.orderConditions.flatMap(
      (orderCondition) => orderCondition.classes,
    );
    const uniqueOrderClasses = [...new Set(availableOrderClasses)];
    return uniqueOrderClasses;
  } else {
    return ['takeaway', 'inhouse'];
  }
};
//this function validates if the item data is available for selected orderType (validates orderConditions.classes) if 3rd argument is passed it will get messageId
export const isOrderConditionClassAvailable = (
  orderType,
  itemData,
  getMessageId,
) => {
  const orderClasses = getOrderClassesFromItemData(itemData);

  const availableInhouse =
    (orderType === ORDER_TYPE_INHOUSE ||
      orderType === ORDER_TYPE_SELF_SERVICE) &&
    (orderClasses.includes('inhouse') ||
      itemData?.orderConditions?.every(({ isActive }) => !isActive)); //at least 1 must be active otherwise its always available

  const availableTakeaway =
    (orderType &&
      [ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY, ORDER_TYPE_CATERING].includes(
        orderType,
      ) &&
      orderClasses.includes('takeaway')) ||
    itemData?.orderConditions?.every(({ isActive }) => !isActive);

  if (availableInhouse) {
    return true;
  } else if (availableTakeaway) {
    return true;
  } else if (getMessageId) {
    return !availableInhouse && orderClasses.includes('takeaway')
      ? 'availableTakeawayLabel'
      : !availableTakeaway && orderClasses.includes('inhouse')
      ? 'availableInhouseLabel'
      : 'noneAvailableLabel';
  } else {
    return false;
  }
};

export const getServiceTimesByPriorty = (orderConditions) => {
  const foundOrderCondition = orderConditions
    ?.sort((a, b) => b.priority - a.priority) // sort DESC by priority (high priority first)
    .find((orderCondition) => orderCondition?.isActive);
  return foundOrderCondition?.serviceTimes;
};

export const fetchAndDownloadFile = async ({
  apiBaseUrl,
  endpoint,
  responseType = 'blob',
  fileType = 'application/octet-stream',
  suggestedFilename,
  onStart = () => {},
  onSuccess = () => {},
  onError = () => {},
}) => {
  onStart();

  try {
    const response = await axios.get(`${apiBaseUrl}/${endpoint}`, {
      responseType,
    });

    const blob = new Blob([response.data], { type: fileType });

    const filename =
      suggestedFilename ||
      response.headers['x-suggested-filename'] ||
      `download.${fileType.split('/')[1]}`;

    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);

    onSuccess(response);
  } catch (error) {
    onError(error);
  }
};

export const getCurrencySuffix = (currency) => {
  if (!currency) {
    return '';
  }

  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol', // or "code" or "name"
  }).format(123);

  return formatted.replace(/\d|,|\.|\s/g, '').trim();
};

export const transformMenuEntries = ({
  menuEntries,
  items,
  itemDatas,
  itemAdditions,
  itemAdditionGroups,
  itemCategories,
  foodAllergens,
  foodAdditives,
  foodPreferences,
  foodIngredients,
}) => {
  return menuEntries.map((entry) => {
    if (entry.type === 'ItemMenuEntry') {
      const item = entry.itemEntry
        ? items.find((i) => i.id === entry.itemEntry.id) || null
        : null;

      const enrichedItem = item
        ? {
            ...item,
            data: itemDatas
              .filter((itemData) => itemData.item?.id === item?.id)
              .map((dataRef) => {
                const itemData =
                  itemDatas.find((d) => d.id === dataRef.id) || null;
                return itemData
                  ? {
                      ...itemData,
                      additionGroups: itemAdditionGroups.filter((group) =>
                        itemData.additionGroups.some(
                          (additionGroup) => group.id === additionGroup?.id,
                        ),
                      ),
                      ingredients: foodIngredients.filter((ingredient) =>
                        itemData.ingredients.some(
                          (itemIngredients) =>
                            itemIngredients.id === ingredient.id,
                        ),
                      ),
                      allergens: foodAllergens.filter((allergen) =>
                        itemData.allergens.some(
                          (itemAllergens) => itemAllergens.id === allergen.id,
                        ),
                      ),
                      preferences: foodPreferences.filter((preference) =>
                        itemData.preferences.some(
                          (itemPreferences) =>
                            itemPreferences.id === preference.id,
                        ),
                      ),
                      additives: foodAdditives.filter((additive) =>
                        itemData.additives.some(
                          (itemAdditives) => itemAdditives.id === additive.id,
                        ),
                      ),
                    }
                  : null;
              })
              .filter(Boolean),
          }
        : null;

      return {
        id: entry.id,
        displayType: entry.displayType,
        manualRanking: entry.manualRanking,
        category: entry.category,
        itemEntry: enrichedItem,
        type: entry.type,
      };
    }

    if (entry.type === 'CategoryMenuEntry') {
      const category = entry.categoryEntry
        ? itemCategories.find((c) => c.id === entry.categoryEntry.id) || null
        : null;

      return {
        category: entry.category,
        id: entry.id,
        displayType: entry.displayType,
        manualRanking: entry.manualRanking,
        categoryEntry: category,
        type: entry.type,
      };
    }

    return entry; // Return unchanged if type is unknown
  });
};

export const transformAdditionGroups = (additionGroups, additions) => {
  return additionGroups.map((additionGroup) => ({
    ...additionGroup,
    entries: additionGroup.entries.map((entry) => ({
      ...entry,
      addition: additions.find((addition) => addition.id === entry.addition.id),
    })),
  }));
};
